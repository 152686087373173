<template>
  <v-dialog v-model="internalValue" persistent max-width="600">
    <v-card class="pb-3">
      <v-card-title class="justify-center text-uppercase">
        <h5>
          {{ `${getTitle} ${partnerName}` }}
        </h5>
        <v-icon class="close-dialog" @click="onClose">
          mdi-close-circle-outline
        </v-icon>
      </v-card-title>
      <v-container>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit">
            <v-layout column class="px-4">
              <!--email-->
              <v-layout column>
                <label class="text-body-2">
                  {{ `${$t("email")} ${$t("account").toLowerCase()} ` }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  :rules="emailRules"
                >
                  <v-text-field
                    v-model="email"
                    outlined
                    dense
                    :filled="isDisabled"
                    :disabled="isDisabled"
                    class="rounded-lg"
                    :maxlength="maximumEmail"
                    :error-messages="errors"
                    :placeholder="`${$t(
                      'place_holders.email_partner'
                    )} ${partnerName}`"
                  />
                </validation-provider>
              </v-layout>
              <!--ID khach hang-->
              <v-layout column>
                <label class="text-body-2">
                  {{ $t("customer_code") }}
                  <span class="red--text mr-2">{{ $t("required") }}</span>
                  <v-tooltip
                    top
                    max-width="250"
                    color="white"
                    class="box-shadow"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        small
                        v-bind="attrs"
                        style="top: -2px"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span style="color: black">
                      {{ $t("tooltip_client_id_ninjavan") }}
                    </span>
                  </v-tooltip>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="customer_code"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="code"
                    outlined
                    dense
                    class="rounded-lg"
                    :placeholder="$t('place_holders.customer_code')"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <!--Token khach hang-->
              <v-layout column>
                <label class="text-body-2">
                  {{ $t("customer_token") }}
                  <span class="red--text mr-2">{{ $t("required") }}</span>
                  <v-tooltip
                    top
                    max-width="250"
                    color="white"
                    class="box-shadow"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        small
                        v-bind="attrs"
                        style="top: -2px"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span style="color: black">
                      {{ $t("tooltip_client_secret_ninjavan") }}
                    </span>
                  </v-tooltip>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="customer_key"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="key"
                    outlined
                    dense
                    class="rounded-lg"
                    :placeholder="$t('place_holders.customer_token')"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <!--branch ID-->
              <v-layout v-if="useShippingMethod == 1" column>
                <label class="text-body-2">
                  {{ $t("branch_id") }}
                  <span class="red--text mr-2">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="branch_id"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="branchId"
                    outlined
                    dense
                    class="rounded-lg"
                    :placeholder="$t('place_holders.branch_id')"
                    maxlength="20"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <!--Hinh thuc gui hang-->
              <v-layout v-if="useShippingMethod === '1'" column>
                <label class="text-body-2">
                  {{ $t("shipping_method") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="selectRequire"
                  vid="shipping_method"
                  :rules="requiredRules"
                >
                  <v-select
                    v-model="methodSelected"
                    clearable
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :placeholder="$t('place_holders.shipping_method')"
                    :items="lstShippingMethod"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <!--dich vu giao hang-->
              <v-layout column>
                <label class="text-body-2">
                  {{ $t("ship_service") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="selectRequire"
                  vid="shipping_service"
                  :rules="requiredRules"
                >
                  <v-select
                    v-model="serviceSelect"
                    clearable
                    outlined
                    dense
                    multiple
                    :filled="isDisabled"
                    :disabled="isDisabled"
                    class="rounded-lg"
                    item-text="serviceDescription"
                    item-value="serviceId"
                    hide-no-data
                    :placeholder="$t('place_holders.ship_service')"
                    :items="lstService"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <!--trang thai-->
              <v-layout
                class="d-flex font-italic font-weight-light text-caption mb-3"
              >
                <label v-if="isCreateNew">
                  {{
                    isActive
                      ? $t("msg_status_new_account_active")
                      : $t("msg_status_new_account_inactive")
                  }}
                </label>
                <label v-else>
                  {{ $t("account_status") }}:
                  <span class="primary--text">{{
                    isActive ? $t("activated") : $t("inactived")
                  }}</span>
                </label>
                <v-switch
                  v-model="isActive"
                  :disabled="isDisabled"
                  class="ml-2 mt-0 pt-0"
                  hide-details
                  inset
                />
              </v-layout>
              <v-layout justify-end>
                <v-btn outlined class="mr-3" @click="onClose">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  v-if="isShowDelete"
                  outlined
                  color="primary"
                  class="mr-3"
                  @click="onDelete(item)"
                >
                  {{ $t("delete") }}
                </v-btn>
                <v-btn color="primary" type="submit" :disabled="invalid">
                  {{ getTextActionOk }}
                </v-btn>
              </v-layout>
            </v-layout>
          </v-form>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from "@/constants";
// import { PartnerService } from '@/services/partnerService'
import { BaseService } from "@/services/baseService";

export default {
  props: {
    value: Boolean,
    item: {
      type: Object,
      default: null,
    },
    partnerName: {
      type: String,
      default: null,
    },
    onAccept: {
      type: Function,
      default: null,
    },
    onDelete: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      useShippingMethod: this.$route.query.useShippingMethod,
      internalValue: this.value,
      partnerId: parseInt(this.$route.query.id),
      requiredRules: {
        required: true,
      },
      isCreateNew: true,
      email: "",
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email,
      },
      code: "",
      maximumSmallText: constants.maximumSmallText,
      key: "",
      branchId: "",
      typeSelected: "",
      methodSelected: "",
      serviceSelect: "",
      status: false,
      lstType: [
        {
          name: this.$t("standard_price"),
          code: constants.pricesType.standard,
        },
        {
          name: this.$t("weight_price"),
          code: constants.pricesType.weight,
        },
      ],
      lstShippingMethod: [
        {
          name: this.$t("self_delivery"),
          code: constants.transportType.individual,
        },
        {
          name: this.$t("shipper_delivery"),
          code: constants.transportType.ship,
        },
      ],
      lstService: [],
      isActive: true,
    };
  },
  computed: {
    getTitle() {
      return this.isCreateNew
        ? this.$t("add_account")
        : this.$t("update_account");
    },
    getTextActionOk() {
      return this.isCreateNew ? this.$t("add") : this.$t("save");
    },
    isDisabled() {
      return !this.isCreateNew;
    },
    isShowDelete() {
      return (
        !this.isCreateNew &&
        this.item &&
        this.item.status === constants.statusPartnerAccount.inactive
      );
    },
    getStatusText() {
      if (this.item) {
        return this.item.status === constants.statusPartnerAccount.active
          ? this.$t("activated")
          : this.$t("inactived");
      }
      return "";
    },
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return;

      this.$emit("input", val);
    },
    value(val, oldVal) {
      if (val === oldVal) return;

      this.internalValue = val;
    },
    item(val) {
      if (val) {
        this.isCreateNew = false;
        this.email = val.email;
        this.code = val.clientId;
        this.key = val.clientSecret;
        this.branchId = val.branchId;
        this.typeSelected = val.priceType;
        this.methodSelected = val.transportType;
        this.serviceSelect = val.serviceId;
        if (val.status === constants.statusPartnerAccount.active) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
        this.$forceUpdate();
      } else {
        this.isCreateNew = true;
        this.clearData();
      }
    },
  },
  created() {
    this.getServiceAll();
  },
  methods: {
    async onClose() {
      if (this.isCreateNew === true) {
        await this.clearData();
      }
      this.$emit("input", false);
    },
    async getServiceAll() {
      try {
        const res = await BaseService.getLogisticPartner();
        if (res && res.data.length) {
          this.lstService = res.data.filter(
            (item) => item.partnerId === this.partnerId
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkActive() {
      if (this.item) {
        this.isActive = false;
        if (this.item.status === constants.statusPartnerAccount.active) {
          this.isActive = true;
        }
      }
    },
    submit() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        const pars = {
          email: this.email.trim(),
          clientId: this.code.trim(),
          clientSecret: this.key.trim(),
          partnerId: this.partnerId,
          status: this.isActive
            ? constants.statusPartnerAccount.active
            : constants.statusPartnerAccount.inactive,
        };
        if (this.item) {
          pars["serviceId"] = this.serviceSelect;
        } else {
          pars["serviceIds"] = this.serviceSelect;
        }
        if (this.useShippingMethod === "1") {
          pars["transportType"] = this.methodSelected;
          pars["branchId"] = this.branchId;
        } else {
          pars["transportType"] = "NONE";
        }
        if (!this.isCreateNew) {
          pars["saleId"] = this.item.id;
        }
        this.onAccept(pars);
      }
    },
    async clearData() {
      this.email = "";
      this.code = "";
      this.key = "";
      this.branchId = "";
      this.typeSelected = "";
      this.methodSelected = "";
      this.serviceSelect = "";
      this.status = false;
      this.isActive = true;
      this.$refs.observer.reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.close-dialog {
  position: absolute !important;
  right: 20px;
  top: 15px;
}
</style>
