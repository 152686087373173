var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_c('v-card',{staticClass:"pb-3"},[_c('v-card-title',{staticClass:"justify-center text-uppercase"},[_c('h5',[_vm._v(" "+_vm._s((_vm.getTitle + " " + _vm.partnerName))+" ")]),_c('v-icon',{staticClass:"close-dialog",on:{"click":_vm.onClose}},[_vm._v(" mdi-close-circle-outline ")])],1),_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{staticClass:"px-4",attrs:{"column":""}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(((_vm.$t("email")) + " " + (_vm.$t("account").toLowerCase()) + " "))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":_vm.isDisabled,"disabled":_vm.isDisabled,"maxlength":_vm.maximumEmail,"error-messages":errors,"placeholder":((_vm.$t(
                    'place_holders.email_partner'
                  )) + " " + _vm.partnerName)},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("customer_code"))+" "),_c('span',{staticClass:"red--text mr-2"},[_vm._v(_vm._s(_vm.$t("required")))]),_c('v-tooltip',{staticClass:"box-shadow",attrs:{"top":"","max-width":"250","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"top":"-2px"},attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("tooltip_client_id_ninjavan"))+" ")])])],1),_c('validation-provider',{attrs:{"name":"textRequired","vid":"customer_code","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":_vm.$t('place_holders.customer_code'),"maxlength":_vm.maximumSmallText,"error-messages":errors},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("customer_token"))+" "),_c('span',{staticClass:"red--text mr-2"},[_vm._v(_vm._s(_vm.$t("required")))]),_c('v-tooltip',{staticClass:"box-shadow",attrs:{"top":"","max-width":"250","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"top":"-2px"},attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("tooltip_client_secret_ninjavan"))+" ")])])],1),_c('validation-provider',{attrs:{"name":"textRequired","vid":"customer_key","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":_vm.$t('place_holders.customer_token'),"maxlength":_vm.maximumSmallText,"error-messages":errors},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})]}}],null,true)})],1),(_vm.useShippingMethod == 1)?_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("branch_id"))+" "),_c('span',{staticClass:"red--text mr-2"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"textRequired","vid":"branch_id","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","placeholder":_vm.$t('place_holders.branch_id'),"maxlength":"20","error-messages":errors},model:{value:(_vm.branchId),callback:function ($$v) {_vm.branchId=$$v},expression:"branchId"}})]}}],null,true)})],1):_vm._e(),(_vm.useShippingMethod === '1')?_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("shipping_method"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"selectRequire","vid":"shipping_method","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","placeholder":_vm.$t('place_holders.shipping_method'),"items":_vm.lstShippingMethod,"error-messages":errors},model:{value:(_vm.methodSelected),callback:function ($$v) {_vm.methodSelected=$$v},expression:"methodSelected"}})]}}],null,true)})],1):_vm._e(),_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("ship_service"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"selectRequire","vid":"shipping_service","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","multiple":"","filled":_vm.isDisabled,"disabled":_vm.isDisabled,"item-text":"serviceDescription","item-value":"serviceId","hide-no-data":"","placeholder":_vm.$t('place_holders.ship_service'),"items":_vm.lstService,"error-messages":errors},model:{value:(_vm.serviceSelect),callback:function ($$v) {_vm.serviceSelect=$$v},expression:"serviceSelect"}})]}}],null,true)})],1),_c('v-layout',{staticClass:"d-flex font-italic font-weight-light text-caption mb-3"},[(_vm.isCreateNew)?_c('label',[_vm._v(" "+_vm._s(_vm.isActive ? _vm.$t("msg_status_new_account_active") : _vm.$t("msg_status_new_account_inactive"))+" ")]):_c('label',[_vm._v(" "+_vm._s(_vm.$t("account_status"))+": "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.isActive ? _vm.$t("activated") : _vm.$t("inactived")))])]),_c('v-switch',{staticClass:"ml-2 mt-0 pt-0",attrs:{"disabled":_vm.isDisabled,"hide-details":"","inset":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":""},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),(_vm.isShowDelete)?_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.onDelete(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.getTextActionOk)+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }